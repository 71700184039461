import React from 'react';

function FooterSession() {
  return (
    <>

      <footer>
        <p className="copyright">
          {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
            Copyright &copy; All rights reserved | Matheus Calaça
            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
        </p>

      </footer>

    </>
  );
}

export default FooterSession;
