import { Avatar, Grid, makeStyles } from '@material-ui/core';
import { Email, GitHub, LinkedIn, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { EnumColors } from '../../enum/EnumColors';
import SocialIcon from '../SocialIcon';


const useStyles = makeStyles((theme) => ({

    space: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    large: {
        width: "250px",
        height: "250px",
    },
    intro: {
        padding: "10px 10px",
        marginTop: "20px",
        marginBottom: "20px",
        background: "#fff"
    },
    link: {
        color: EnumColors.YELLOW,
        '&:hover': {
            color: EnumColors.PURPLE_MIDLE,
        }
    }
}));


function DescribeSession() {
    const classes = useStyles();

    return (
        <>
            <section className="intro-section">
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item md={10} lg={8}>
                        <Grid container item className={classes.intro}>
                            <Grid item container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item    >
                                    <Avatar className={classes.large} src="./resource/images/my-photo.svg" alt="Uma foto pessoal para o perfil" />
                                </Grid>
                            </Grid>
                            <Grid item container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Grid item container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center" xs={12}>
                                        <h2><b>Matheus Calaça</b></h2>
                                    </Grid>
                                    <Grid item container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center" xs={12}>
                                        <h4 className="font-yellow">Engenheiro de Software Pleno</h4>
                                    </Grid>
                                </Grid>
                                <span className={classes.space} ></span>
                                <Grid item
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center" >
                                    <Grid item container
                                        direction="row"
                                        justify="center"
                                        alignItems="center" xs={12}>
                                        <b>EMAIL : </b> <a href="mailto:matheusfcalaca@gmail.com" className={classes.link}> matheusfcalaca@gmail.com</a>
                                    </Grid>
                                    <span className={classes.space} ></span>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={3}
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <SocialIcon myLink="https://www.linkedin.com/in/matheuscalaca/" myClassName={<LinkedIn />} Myicon="linkedin" />
                                        </Grid>
                                        <Grid
                                            item
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <SocialIcon myLink="https://github.com/matheusCalaca" myClassName={<GitHub />} Myicon="github" />
                                        </Grid>
                                        <Grid
                                            item
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <SocialIcon myLink="mailto:matheusfcalaca@gmail.com" myClassName={<Email />} Myicon="mail" />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <SocialIcon myLink="https://wa.me/5562982507424" myClassName={<WhatsApp />} Myicon="whatsapp" />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </>
    );
}

export default DescribeSession;
